import { Controller } from "stimulus";
import Sortable from "sortablejs";


export default class extends Controller {
  initialOrder = [];
  connect() {
    this.sortable = Sortable.create(this.element, {
      onStart: this.start.bind(this),
      onEnd: this.end.bind(this),
      handle: this.element.dataset.dragHandle,
      animation: 150
    })
  }

  start(event) {
    this.initialOrder = [...event.from.children]
  }

  end(event) {
    let url = event.item.dataset.url;
    let data = new FormData();
    let newPosition = this.initialOrder[event.newIndex].dataset.position

    data.append("position", newPosition)
    event.item.dataset.position = newPosition

    fetch(url, {
      method: "PUT",
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
      },
      body: data
    })
  }
}
