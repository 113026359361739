import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["item", "content"];

  connect() {
    this.openFirstAccordion();
  }

  toggle(event) {
    const clickedItem = event.currentTarget;
    const content = clickedItem.nextElementSibling;

    this.contentTargets.forEach((el) => {
      if (el !== content) {
        this.closeAccordion(el);
      }
    });

    if (content.style.height) {
      this.closeAccordion(content);
    } else {
      this.openAccordion(content);
    }
  }

  openFirstAccordion() {
    this.openAccordion(this.contentTargets[0]);
  }

  closeAccordion(el) {
    el.style.height = null;
    el.previousElementSibling.querySelector(".fa-caret-up")
      .classList.add("hidden");
    el.previousElementSibling.querySelector(".fa-caret-down")
      .classList.remove("hidden");
  }

  openAccordion(el) {
    el.style.height = el.scrollHeight + "px";
    el.previousElementSibling.querySelector(".fa-caret-up")
      .classList.remove("hidden");
    el.previousElementSibling.querySelector(".fa-caret-down")
      .classList.add("hidden");
  }
}
